@font-face {
    font-family: "ias-icons";

    src: url("./fonts/ias-icons.eot?70f73418ab8559da4e5460b698516760?#iefix") format("embedded-opentype"),
url("./fonts/ias-icons.woff2?70f73418ab8559da4e5460b698516760") format("woff2"),
url("./fonts/ias-icons.woff?70f73418ab8559da4e5460b698516760") format("woff"),
url("./fonts/ias-icons.ttf?70f73418ab8559da4e5460b698516760") format("truetype"),
url("./fonts/ias-icons.svg?70f73418ab8559da4e5460b698516760#ias-icons") format("svg");
}

.ias-icon {
    display: inline-block;
}

.ias-icon:before {
    font-family: "ias-icons";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    /* speak: none; only necessary if not using the private unicode range (firstGlyph option) */
    text-decoration: none;
    text-transform: none;
}


.ias-icon-colors:before {
    content: "\e9b0";
}

.ias-icon-brand_console:before {
    content: "\e9b1";
}

.ias-icon-brand_settings:before {
    content: "\e9b2";
}

.ias-icon-brand_user:before {
    content: "\e9b3";
}

.ias-icon-sys_suse:before {
    content: "\e9ca";
}

.ias-icon-open_in_window:before {
    content: "\e9e6";
}

.ias-icon-partner:before {
    content: "\e9e9";
}

.ias-icon-down_thick:before {
    content: "\e900";
}

.ias-icon-down_thin:before {
    content: "\e901";
}

.ias-icon-up_thick:before {
    content: "\e902";
}

.ias-icon-up_thin:before {
    content: "\e903";
}

.ias-icon-new_thick:before {
    content: "\e904";
}

.ias-icon-new_thin:before {
    content: "\e905";
}

.ias-icon-close_thick:before {
    content: "\e906";
}

.ias-icon-close_thin:before {
    content: "\e907";
}

.ias-icon-check_thick:before {
    content: "\e908";
}

.ias-icon-check_thin:before {
    content: "\e909";
}

.ias-icon-search_thick:before {
    content: "\e90a";
}

.ias-icon-search_thin:before {
    content: "\e90b";
}

.ias-icon-prev_left_thick:before {
    content: "\e90c";
}

.ias-icon-prev_left_thin:before {
    content: "\e90d";
}

.ias-icon-next_right_thick:before {
    content: "\e90e";
}

.ias-icon-next_right_thin:before {
    content: "\e90f";
}

.ias-icon-flow_goto_next_thick:before {
    content: "\e910";
}

.ias-icon-flow_goto_next_thin:before {
    content: "\e911";
}

.ias-icon-flow_goto_prev_thick:before {
    content: "\e912";
}

.ias-icon-flow_goto_prev_thin:before {
    content: "\e913";
}

.ias-icon-forward_right_thick:before {
    content: "\e914";
}

.ias-icon-forward_right_thin:before {
    content: "\e915";
}

.ias-icon-back_left_thick:before {
    content: "\e916";
}

.ias-icon-back_left_thin:before {
    content: "\e917";
}

.ias-icon-flow_return_thick:before {
    content: "\e918";
}

.ias-icon-flow_return_thin:before {
    content: "\e919";
}

.ias-icon-minus_thick:before {
    content: "\e91a";
}

.ias-icon-reload_refresh_thick:before {
    content: "\e91b";
}

.ias-icon-reload_refresh_thin:before {
    content: "\e91c";
}

.ias-icon-circle_horz_menu_fill:before {
    content: "\e91d";
}

.ias-icon-circle_horz_menu_thin:before {
    content: "\e91e";
}

.ias-icon-circle_vert_menu_fill:before {
    content: "\e91f";
}

.ias-icon-circle_ver_menu_thin:before {
    content: "\e920";
}

.ias-icon-favorite_outline_thin:before {
    content: "\e921";
}

.ias-icon-favorite_fill:before {
    content: "\e922";
}

.ias-icon-status_ok_thick:before {
    content: "\e923";
}

.ias-icon-status_ok_thin:before {
    content: "\e924";
}

.ias-icon-status_ok_fill:before {
    content: "\e925";
}

.ias-icon-status_error_thick:before {
    content: "\e926";
}

.ias-icon-status_error_thin:before {
    content: "\e927";
}

.ias-icon-status_error_fill:before {
    content: "\e928";
}

.ias-icon-status_warn_thick:before {
    content: "\e929";
}

.ias-icon-status_warn_thin:before {
    content: "\e92a";
}

.ias-icon-status_warn_fill:before {
    content: "\e92b";
}

.ias-icon-message_error_thick:before {
    content: "\e92c";
}

.ias-icon-message_error_red_fill:before {
    content: "\e92d";
}

.ias-icon-status_disconnected_thick:before {
    content: "\e92e";
}

.ias-icon-status_disconnected_thin:before {
    content: "\e92f";
}

.ias-icon-status_info_thick:before {
    content: "\e930";
}

.ias-icon-status_denied_thick:before {
    content: "\e931";
}

.ias-icon-status_denied_thin:before {
    content: "\e932";
}

.ias-icon-status_pending_thick:before {
    content: "\e933";
}

.ias-icon-status_pending_thin:before {
    content: "\e934";
}

.ias-icon-status_retracted_thick:before {
    content: "\e935";
}

.ias-icon-sys_mac:before {
    content: "\e936";
}

.ias-icon-status_retracted_thin:before {
    content: "\e937";
}

.ias-icon-status_running_thick:before {
    content: "\e938";
}

.ias-icon-status_running_thin:before {
    content: "\e939";
}

.ias-icon-filter_thick:before {
    content: "\e93a";
}

.ias-icon-filter_clear_thick:before {
    content: "\e93b";
}

.ias-icon-comment_sys_thin:before {
    content: "\e93c";
}

.ias-icon-comment_user_thin:before {
    content: "\e93d";
}

.ias-icon-comment_thick:before {
    content: "\e93e";
}

.ias-icon-start_thick:before {
    content: "\e93f";
}

.ias-icon-start_thin:before {
    content: "\e940";
}

.ias-icon-stop_thick:before {
    content: "\e941";
}

.ias-icon-stop_thin:before {
    content: "\e942";
}

.ias-icon-control_play_thick:before {
    content: "\e943";
}

.ias-icon-control_play_thin:before {
    content: "\e944";
}

.ias-icon-control_rewind_thick:before {
    content: "\e945";
}

.ias-icon-control_rewind_thin:before {
    content: "\e946";
}

.ias-icon-control_fast_forward_thick:before {
    content: "\e947";
}

.ias-icon-control_fast_forward_thin:before {
    content: "\e948";
}

.ias-icon-control_fast_rewind_thick:before {
    content: "\e949";
}

.ias-icon-control_fast_rewind_thin:before {
    content: "\e94a";
}

.ias-icon-control_goto_end_thick:before {
    content: "\e94b";
}

.ias-icon-control_goto_end_thin:before {
    content: "\e94c";
}

.ias-icon-control_goto_start_thick:before {
    content: "\e94d";
}

.ias-icon-control_goto_start_thin:before {
    content: "\e94e";
}

.ias-icon-control_pause_thick:before {
    content: "\e94f";
}

.ias-icon-control_pause_thin:before {
    content: "\e950";
}

.ias-icon-control_stop_thick:before {
    content: "\e951";
}

.ias-icon-control_stop_thin:before {
    content: "\e952";
}

.ias-icon-view_tile_thick:before {
    content: "\e953";
}

.ias-icon-view_tile_thin:before {
    content: "\e954";
}

.ias-icon-view_list_thick:before {
    content: "\e955";
}

.ias-icon-view_list_thin:before {
    content: "\e956";
}

.ias-icon-file_thick:before {
    content: "\e957";
}

.ias-icon-file_thin:before {
    content: "\e958";
}

.ias-icon-doc_thick:before {
    content: "\e959";
}

.ias-icon-doc_thin:before {
    content: "\e95a";
}

.ias-icon-report_thick:before {
    content: "\e95b";
}

.ias-icon-report_thin:before {
    content: "\e95c";
}

.ias-icon-export_to_doc_thick:before {
    content: "\e95d";
}

.ias-icon-export_to_doc_thin:before {
    content: "\e95e";
}

.ias-icon-bookmark_thick:before {
    content: "\e95f";
}

.ias-icon-bookmark_thin:before {
    content: "\e960";
}

.ias-icon-folder_new_thick:before {
    content: "\e961";
}

.ias-icon-folder_new_thin:before {
    content: "\e962";
}

.ias-icon-folder_thick:before {
    content: "\e963";
}

.ias-icon-folder_thin:before {
    content: "\e964";
}

.ias-icon-image_thick:before {
    content: "\e965";
}

.ias-icon-image_thin:before {
    content: "\e966";
}

.ias-icon-logging_thin:before {
    content: "\e967";
}

.ias-icon-storage_thin:before {
    content: "\e968";
}

.ias-icon-certificate_thin:before {
    content: "\e969";
}

.ias-icon-brand_thin:before {
    content: "\e96a";
}

.ias-icon-theme_thin:before {
    content: "\e96b";
}

.ias-icon-audit_thin:before {
    content: "\e96c";
}

.ias-icon-audit2_thin:before {
    content: "\e96d";
}

.ias-icon-delete_thick:before {
    content: "\e96e";
}

.ias-icon-delete_thin:before {
    content: "\e96f";
}

.ias-icon-signout_thick:before {
    content: "\e970";
}

.ias-icon-signout_thin:before {
    content: "\e971";
}

.ias-icon-claim_thick:before {
    content: "\e972";
}

.ias-icon-claim_thin:before {
    content: "\e973";
}

.ias-icon-history_thick:before {
    content: "\e974";
}

.ias-icon-history_thin:before {
    content: "\e975";
}

.ias-icon-configure_thick:before {
    content: "\e976";
}

.ias-icon-configure_thin:before {
    content: "\e977";
}

.ias-icon-hamburger_menu_thick:before {
    content: "\e978";
}

.ias-icon-hamburger_menu_thin:before {
    content: "\e979";
}

.ias-icon-date_thick:before {
    content: "\e97a";
}

.ias-icon-date_thin:before {
    content: "\e97b";
}

.ias-icon-device_monitor_thin:before {
    content: "\e97c";
}

.ias-icon-device_mobile_thin:before {
    content: "\e97d";
}

.ias-icon-phone_thick:before {
    content: "\e97e";
}

.ias-icon-phone_mobile_thick:before {
    content: "\e97f";
}

.ias-icon-notify_thick:before {
    content: "\e980";
}

.ias-icon-resource_assign:before {
    content: "\e981";
}

.ias-icon-resource_thick:before {
    content: "\e982";
}

.ias-icon-time_thick:before {
    content: "\e983";
}

.ias-icon-location_thick:before {
    content: "\e984";
}

.ias-icon-world_thin:before {
    content: "\e985";
}

.ias-icon-email_thick:before {
    content: "\e986";
}

.ias-icon-email_thin:before {
    content: "\e987";
}

.ias-icon-fingerprint_thin:before {
    content: "\e988";
}

.ias-icon-card_thin:before {
    content: "\e989";
}

.ias-icon-home_thick:before {
    content: "\e98a";
}

.ias-icon-home_thin:before {
    content: "\e98b";
}

.ias-icon-lock_thick:before {
    content: "\e98c";
}

.ias-icon-lock_thin:before {
    content: "\e98d";
}

.ias-icon-unlock_thick:before {
    content: "\e98e";
}

.ias-icon-unlock_thin:before {
    content: "\e98f";
}

.ias-icon-password_thin:before {
    content: "\e990";
}

.ias-icon-connector_thin:before {
    content: "\e991";
}

.ias-icon-connector_sso_thin:before {
    content: "\e992";
}

.ias-icon-orgchart_thin:before {
    content: "\e993";
}

.ias-icon-select_thick:before {
    content: "\e994";
}

.ias-icon-select_thin:before {
    content: "\e995";
}

.ias-icon-size_contract_thick:before {
    content: "\e996";
}

.ias-icon-size_contract_thin:before {
    content: "\e997";
}

.ias-icon-size_expand_thick:before {
    content: "\e998";
}

.ias-icon-size_expand_thin:before {
    content: "\e999";
}

.ias-icon-sort_thick:before {
    content: "\e99a";
}

.ias-icon-sort_thin:before {
    content: "\e99b";
}

.ias-icon-shortcut_thick:before {
    content: "\e99c";
}

.ias-icon-shortcut_thin:before {
    content: "\e99d";
}

.ias-icon-cancel_thick:before {
    content: "\e99e";
}

.ias-icon-cancel_thin:before {
    content: "\e99f";
}

.ias-icon-help_thick:before {
    content: "\e9a0";
}

.ias-icon-help_thin:before {
    content: "\e9a1";
}

.ias-icon-reset_thick:before {
    content: "\e9a2";
}

.ias-icon-reset_thin:before {
    content: "\e9a3";
}

.ias-icon-download_thick:before {
    content: "\e9a4";
}

.ias-icon-edit_thick:before {
    content: "\e9a5";
}

.ias-icon-save_thick:before {
    content: "\e9a6";
}

.ias-icon-group_thin:before {
    content: "\e9a7";
}

.ias-icon-user_thin:before {
    content: "\e9a8";
}

.ias-icon-profile_thin:before {
    content: "\e9a9";
}

.ias-icon-profile_2_thin:before {
    content: "\e9aa";
}

.ias-icon-auditor_thin:before {
    content: "\e9ab";
}

.ias-icon-admin_thin:before {
    content: "\e9ac";
}

.ias-icon-admin_super_thin:before {
    content: "\e9ad";
}

.ias-icon-support_thick:before {
    content: "\e9ae";
}

.ias-icon-chart:before {
    content: "\e9af";
}

.ias-icon-admin_user_audit:before {
    content: "\e9b4";
}

.ias-icon-admin_user_regular:before {
    content: "\e9b5";
}

.ias-icon-admin_user_super:before {
    content: "\e9b6";
}

.ias-icon-admin_user_view:before {
    content: "\e9b7";
}

.ias-icon-question_mark:before {
    content: "\e9b8";
}

.ias-icon-upload_cloud:before {
    content: "\e9b9";
}

.ias-icon-upload_generic:before {
    content: "\e9ba";
}

.ias-icon-team:before {
    content: "\e9bb";
}

.ias-icon-role:before {
    content: "\e9bc";
}

.ias-icon-role_manage:before {
    content: "\e9bd";
}

.ias-icon-all_users:before {
    content: "\e9be";
}

.ias-icon-relationship:before {
    content: "\e9bf";
}

.ias-icon-password_policy_thick:before {
    content: "\e9c0";
}

.ias-icon-tools:before {
    content: "\e9c1";
}

.ias-icon-comment_user_thick:before {
    content: "\e9c2";
}

.ias-icon-filter_adv:before {
    content: "\e9c3";
}

.ias-icon-comment_sys_thick:before {
    content: "\e9c4";
}

.ias-icon-provision_request_def:before {
    content: "\e9c5";
}

.ias-icon-server_general_thick:before {
    content: "\e9c6";
}

.ias-icon-server_general_thin:before {
    content: "\e9c7";
}

.ias-icon-server_virtual_thick:before {
    content: "\e9c8";
}

.ias-icon-sys_linux:before {
    content: "\e9c9";
}

.ias-icon-sys_unix:before {
    content: "\e9cb";
}

.ias-icon-sys_windows:before {
    content: "\e9cc";
}

.ias-icon-server_virtual_thin:before {
    content: "\e9cd";
}

.ias-icon-app_thick:before {
    content: "\e9ce";
}

.ias-icon-cloud:before {
    content: "\e9cf";
}

.ias-icon-database:before {
    content: "\e9d0";
}

.ias-icon-app_thin:before {
    content: "\e9d1";
}

.ias-icon-password_thick:before {
    content: "\e9d2";
}

.ias-icon-password_policy_thin:before {
    content: "\e9d3";
}

.ias-icon-signin:before {
    content: "\e9d4";
}

.ias-icon-admin_new_user:before {
    content: "\e9d5";
}

.ias-icon-password_forgot_thick:before {
    content: "\e9d6";
}

.ias-icon-password_forgot_thin:before {
    content: "\e9d7";
}

.ias-icon-number_1:before {
    content: "\e9d8";
}

.ias-icon-number_2:before {
    content: "\e9d9";
}

.ias-icon-number_3:before {
    content: "\e9da";
}

.ias-icon-number_4:before {
    content: "\e9db";
}

.ias-icon-number_5:before {
    content: "\e9dc";
}

.ias-icon-number_6:before {
    content: "\e9dd";
}

.ias-icon-number_7:before {
    content: "\e9de";
}

.ias-icon-number_8:before {
    content: "\e9df";
}

.ias-icon-number_9:before {
    content: "\e9e0";
}

.ias-icon-strength1:before {
    content: "\e9e1";
}

.ias-icon-strength2:before {
    content: "\e9e2";
}

.ias-icon-strength3:before {
    content: "\e9e3";
}

.ias-icon-strength4:before {
    content: "\e9e4";
}

.ias-icon-strength5:before {
    content: "\e9e5";
}

.ias-icon-queue_list:before {
    content: "\e9e7";
}

.ias-icon-queue_list_add:before {
    content: "\e9e8";
}

.ias-icon-sync:before {
    content: "\e9ea";
}

.ias-icon-publish:before {
    content: "\e9eb";
}

.ias-icon-move:before {
    content: "\e9ec";
}

.ias-icon-password_configure:before {
    content: "\e9ed";
}

.ias-icon-password_role_based:before {
    content: "\e9ee";
}

.ias-icon-search_advanced:before {
    content: "\e9ef";
}

.ias-icon-share:before {
    content: "\e9f0";
}

.ias-icon-copy_duplicate:before {
    content: "\e9f1";
}

.ias-icon-show_all:before {
    content: "\e9f2";
}

.ias-icon-separation_of_duties:before {
    content: "\e9f3";
}

.ias-icon-network:before {
    content: "\e9f4";
}

.ias-icon-microphone:before {
    content: "\e9f5";
}

.ias-icon-swisscom_mobile_id:before {
    content: "\e9f6";
}

.ias-icon-bankid:before {
    content: "\e9f7";
}

.ias-icon-security_questions:before {
    content: "\e9f8";
}

.ias-icon-secure_identity:before {
    content: "\e9f9";
}

.ias-icon-pki_public_key:before {
    content: "\e9fa";
}

.ias-icon-facial_recognition:before {
    content: "\e9fb";
}

.ias-icon-bluetooth:before {
    content: "\e9fc";
}

.ias-icon-usb:before {
    content: "\e9fd";
}

.ias-icon-card_chip:before {
    content: "\e9fe";
}

.ias-icon-password_ldap:before {
    content: "\e9ff";
}

.ias-icon-password_emergency:before {
    content: "\ea00";
}

.ias-icon-database_search:before {
    content: "\ea01";
}

.ias-icon-status_new:before {
    content: "\ea02";
}

.ias-icon-status_edited:before {
    content: "\ea03";
}

.ias-icon-status_deleted:before {
    content: "\ea04";
}

.ias-icon-contract_all:before {
    content: "\ea05";
}

.ias-icon-expand_all:before {
    content: "\ea06";
}

.ias-icon-page_simple:before {
    content: "\ea07";
}

.ias-icon-page_complex:before {
    content: "\ea08";
}

.ias-icon-policy:before {
    content: "\ea09";
}

.ias-icon-policy_certificate:before {
    content: "\ea0a";
}

.ias-icon-policy_rules:before {
    content: "\ea0b";
}

.ias-icon-parameters:before {
    content: "\ea0c";
}

.ias-icon-user_history:before {
    content: "\ea0d";
}

.ias-icon-directory_tree:before {
    content: "\ea0e";
}

.ias-icon-directory_org:before {
    content: "\ea0f";
}

.ias-icon-directory_org_unit:before {
    content: "\ea10";
}

.ias-icon-certificate_manage:before {
    content: "\ea11";
}

.ias-icon-rights:before {
    content: "\ea12";
}

.ias-icon-printer:before {
    content: "\ea13";
}

.ias-icon-tag:before {
    content: "\ea14";
}

.ias-icon-numeric_code:before {
    content: "\ea15";
}

.ias-icon-search_reports:before {
    content: "\ea16";
}

.ias-icon-secure_web:before {
    content: "\ea17";
}

.ias-icon-dir_NDSPKI_trusted_root_object:before {
    content: "\ea19";
}

.ias-icon-dir_NDSPKI_trusted_root:before {
    content: "\ea1a";
}

.ias-icon-dir_NDSPKI_certificate_authority:before {
    content: "\ea1b";
}

.ias-icon-dir_NDSPKI_key_material:before {
    content: "\ea1c";
}

.ias-icon-dir_NDSPKI_SDkey_list:before {
    content: "\ea1d";
}

.ias-icon-dir_NDSPKI_SDkeyAccess_container:before {
    content: "\ea1e";
}

.ias-icon-dir_SASsecurity:before {
    content: "\ea1f";
}

.ias-icon-dir_SASservice:before {
    content: "\ea20";
}

.ias-icon-dir_MASVsecurity_policy:before {
    content: "\ea21";
}

.ias-icon-dir_SASlogin_method_container:before {
    content: "\ea22";
}

.ias-icon-edit_doc:before {
    content: "\ea23";
}

.ias-icon-save_disk:before {
    content: "\ea24";
}

.ias-icon-phone_keypad:before {
    content: "\ea25";
}

.ias-icon-keypad:before {
    content: "\ea26";
}

.ias-icon-dir_external_entity:before {
    content: "\ea27";
}

.ias-icon-dir_object_bindery:before {
    content: "\ea28";
}

.ias-icon-dir_queue_bindery:before {
    content: "\ea29";
}

.ias-icon-dir_group_message_routing:before {
    content: "\ea2a";
}

.ias-icon-dir_group_dynamic:before {
    content: "\ea2b";
}

.ias-icon-dir_list:before {
    content: "\ea2c";
}

.ias-icon-dir_object:before {
    content: "\ea2d";
}

.ias-icon-dir_template:before {
    content: "\ea2e";
}

.ias-icon-dir_server_ncp:before {
    content: "\ea2f";
}

.ias-icon-dir_server_afp:before {
    content: "\ea30";
}

.ias-icon-dir_server_messaging:before {
    content: "\ea31";
}

.ias-icon-dir_server_print:before {
    content: "\ea32";
}

.ias-icon-dir_server_http:before {
    content: "\ea33";
}

.ias-icon-dir_replica:before {
    content: "\ea34";
}

.ias-icon-dir_replica_encrypted:before {
    content: "\ea35";
}

.ias-icon-dir_server_ldap:before {
    content: "\ea36";
}

.ias-icon-dir_ndsPredicate_stats:before {
    content: "\ea37";
}

.ias-icon-dir_schema:before {
    content: "\ea38";
}

.ias-icon-dir_schema_attribute:before {
    content: "\ea39";
}

.ias-icon-dir_schema_attribute_encrypted:before {
    content: "\ea3a";
}

.ias-icon-dir_org_person:before {
    content: "\ea3b";
}

.ias-icon-dir_person:before {
    content: "\ea3c";
}

.ias-icon-dir_profile_ldap:before {
    content: "\ea3d";
}

.ias-icon-dir_profile:before {
    content: "\ea3e";
}

.ias-icon-dir_mapping:before {
    content: "\ea3f";
}

.ias-icon-dir_volume:before {
    content: "\ea40";
}

.ias-icon-dir_group_ldap:before {
    content: "\ea41";
}

.ias-icon-dir_country:before {
    content: "\ea42";
}

.ias-icon-dir_locality:before {
    content: "\ea43";
}

.ias-icon-dir_app_process:before {
    content: "\ea44";
}

.ias-icon-code_1:before {
    content: "\ea45";
}

.ias-icon-dir_SASlogin_policy:before {
    content: "\ea46";
}

.ias-icon-dir_details:before {
    content: "\ea47";
}

.ias-icon-dir_LDAP:before {
    content: "\ea48";
}

.ias-icon-security:before {
    content: "\ea49";
}

.ias-icon-security_lock:before {
    content: "\ea4a";
}

.ias-icon-security_policy:before {
    content: "\ea4b";
}

.ias-icon-phone_message:before {
    content: "\ea4c";
}

.ias-icon-edit_small:before {
    content: "\ea4d";
}

.ias-icon-permission_request:before {
    content: "\ea4e";
}

.ias-icon-dir_DN_query:before {
    content: "\ea4f";
}

.ias-icon-dynamic_entity:before {
    content: "\ea50";
}

.ias-icon-data_item:before {
    content: "\ea51";
}

.ias-icon-data_item_mapping:before {
    content: "\ea52";
}

.ias-icon-dir_DN_display:before {
    content: "\ea53";
}

.ias-icon-label_element:before {
    content: "\ea54";
}

.ias-icon-title_element:before {
    content: "\ea55";
}

.ias-icon-server_access:before {
    content: "\ea56";
}

.ias-icon-server_identity:before {
    content: "\ea57";
}

.ias-icon-server_radius:before {
    content: "\ea58";
}

.ias-icon-server_services:before {
    content: "\ea59";
}

.ias-icon-server_wireless:before {
    content: "\ea5a";
}

.ias-icon-server_commexec:before {
    content: "\ea5b";
}

.ias-icon-password_sql:before {
    content: "\ea5c";
}

.ias-icon-secure_mobile:before {
    content: "\ea5d";
}

.ias-icon-secure_device:before {
    content: "\ea5e";
}

.ias-icon-secure-admin:before {
    content: "\ea5f";
}

.ias-icon-dir_object_details:before {
    content: "\ea60";
}

.ias-icon-dir_DN_object:before {
    content: "\ea61";
}

.ias-icon-dir_cRLDistributionPoint:before {
    content: "\ea62";
}

.ias-icon-dir_effective_rights:before {
    content: "\ea63";
}

.ias-icon-dir_encryption_Policy:before {
    content: "\ea64";
}

.ias-icon-down_level:before {
    content: "\ea65";
}

.ias-icon-dir_NDSPKI_certificate:before {
    content: "\ea66";
}

.ias-icon-dir_ndspki_container:before {
    content: "\ea67";
}

.ias-icon-up_level:before {
    content: "\ea68";
}

.ias-icon-dir_ndspki_CRLConfiguration:before {
    content: "\ea69";
}

.ias-icon-email_configure:before {
    content: "\ea6a";
}

.ias-icon-email_menu_1:before {
    content: "\ea6b";
}

.ias-icon-email_menu_2:before {
    content: "\ea6c";
}

.ias-icon-email_secure:before {
    content: "\ea6d";
}

.ias-icon-email_share:before {
    content: "\ea6e";
}

.ias-icon-fingerprint_2:before {
    content: "\ea6f";
}

.ias-icon-dir_notfMergeTemplate:before {
    content: "\ea70";
}

.ias-icon-dir_notfTemplateCollection:before {
    content: "\ea71";
}

.ias-icon-dir_nspmPasswordPolicy:before {
    content: "\ea72";
}

.ias-icon-dir_nspmPasswordPolicyContainer:before {
    content: "\ea73";
}

.ias-icon-dir_nspmPolicyAgent:before {
    content: "\ea74";
}

.ias-icon-dir_prSyncPolicy:before {
    content: "\ea75";
}

.ias-icon-dir_rbsBook:before {
    content: "\ea76";
}

.ias-icon-dir_rbsBook2:before {
    content: "\ea77";
}

.ias-icon-dir_rbsCollection:before {
    content: "\ea78";
}

.ias-icon-dir_rbsCollection2:before {
    content: "\ea79";
}

.ias-icon-server_network:before {
    content: "\ea7a";
}

.ias-icon-server_security:before {
    content: "\ea7b";
}

.ias-icon-server_web:before {
    content: "\ea7c";
}

.ias-icon-dir_rbsModule:before {
    content: "\ea80";
}

.ias-icon-dir_rbsModule2:before {
    content: "\ea81";
}

.ias-icon-dir_rbsRole2:before {
    content: "\ea82";
}

.ias-icon-dir_rbsScope:before {
    content: "\ea83";
}

.ias-icon-dir_rbsScope2:before {
    content: "\ea84";
}

.ias-icon-dir_rbsTask:before {
    content: "\ea85";
}

.ias-icon-dir_rbsTask2:before {
    content: "\ea86";
}

.ias-icon-dir_sasPostLoginMethod:before {
    content: "\ea88";
}

.ias-icon-dir_sasPostLoginMethodContainer:before {
    content: "\ea89";
}

.ias-icon-password_reset:before {
    content: "\ea8a";
}

.ias-icon-password_sync:before {
    content: "\ea8b";
}

.ias-icon-NEMID:before {
    content: "\ea8d";
}

.ias-icon-NEMID_Denmark:before {
    content: "\ea8e";
}

.ias-icon-HANIS_SouthAfrica:before {
    content: "\ea8f";
}

.ias-icon-dir_snmpAdmin:before {
    content: "\ea90";
}

.ias-icon-dir_snmpGroup:before {
    content: "\ea91";
}

.ias-icon-dir_snmpMessageGroup:before {
    content: "\ea92";
}

.ias-icon-dir_sssServerPolicies:before {
    content: "\ea93";
}

.ias-icon-dir_sssServerPolicyOverride:before {
    content: "\ea94";
}

.ias-icon-dir_trustees:before {
    content: "\ea95";
}

.ias-icon-dir_domain:before {
    content: "\ea97";
}

.ias-icon-arrow_down:before {
    content: "\ea98";
}

.ias-icon-arrow_left:before {
    content: "\ea99";
}

.ias-icon-data_flow:before {
    content: "\ea9a";
}

.ias-icon-object_inspect:before {
    content: "\ea9c";
}

.ias-icon-arrow_right:before {
    content: "\eb01";
}

.ias-icon-arrow_up:before {
    content: "\eb02";
}

.ias-icon-partition:before {
    content: "\eb03";
}

.ias-icon-merge:before {
    content: "\eb04";
}

.ias-icon-flex_OTP:before {
    content: "\eb05";
}

.ias-icon-multi_paths:before {
    content: "\eb06";
}

.ias-icon-index:before {
    content: "\eb07";
}

.ias-icon-Apple_touch_ID:before {
    content: "\eb08";
}

.ias-icon-view:before {
    content: "\eb09";
}

.ias-icon-manager_user_1:before {
    content: "\eb0a";
}

.ias-icon-manager_user_2:before {
    content: "\eb0b";
}

.ias-icon-manager_user_3:before {
    content: "\eb0c";
}

.ias-icon-app_oauth:before {
    content: "\eb0d";
}

.ias-icon-app_saml:before {
    content: "\eb0e";
}

.ias-icon-app_simple:before {
    content: "\eb0f";
}

.ias-icon-idm_driver:before {
    content: "\eb10";
}

.ias-icon-idm_driverset:before {
    content: "\eb11";
}

.ias-icon-dashboard_1:before {
    content: "\eb12";
}

.ias-icon-dashboard_2:before {
    content: "\eb13";
}

.ias-icon-status_stopped:before {
    content: "\eb14";
}

.ias-icon-status_start:before {
    content: "\eb15";
}

.ias-icon-status_stop:before {
    content: "\eb16";
}

.ias-icon-status_stop_fill:before {
    content: "\eb17";
}

.ias-icon-circle:before {
    content: "\eb18";
}

.ias-icon-circle_fill:before {
    content: "\eb19";
}

.ias-icon-file_delete:before {
    content: "\eb1a";
}

.ias-icon-file_lock:before {
    content: "\eb1b";
}

.ias-icon-file_ok:before {
    content: "\eb1c";
}

.ias-icon-file_upload:before {
    content: "\eb1d";
}

.ias-icon-file_compare:before {
    content: "\eb1e";
}

.ias-icon-trace_monitor:before {
    content: "\eb1f";
}

.ias-icon-idm_overview:before {
    content: "\eb20";
}

.ias-icon-hide_private:before {
    content: "\eb21";
}

.ias-icon-email_approve:before {
    content: "\eb22";
}

.ias-icon-email_user:before {
    content: "\eb23";
}

.ias-icon-email_group:before {
    content: "\eb24";
}

.ias-icon-driver_inspect:before {
    content: "\eb25";
}

.ias-icon-driver_health_config:before {
    content: "\eb26";
}

.ias-icon-driver_cache_inspect:before {
    content: "\eb27";
}

.ias-icon-access:before {
    content: "\eb28";
}

.ias-icon-email_password:before {
    content: "\eb29";
}

.ias-icon-reciprocal:before {
    content: "\eb2b";
}

.ias-icon-library:before {
    content: "\eb2c";
}

.ias-icon-user_locked:before {
    content: "\eb2d";
}

.ias-icon-add_row:before {
    content: "\eb2e";
}

.ias-icon-add_column:before {
    content: "\eb2f";
}

.ias-icon-shop:before {
    content: "\eb2a";
}

.ias-icon-secure_login:before {
    content: "\eb30";
}

.ias-icon-class:before {
    content: "\eb31";
}

.ias-icon-class_add:before {
    content: "\eb32";
}

.ias-icon-attribute:before {
    content: "\eb33";
}

.ias-icon-attribute_add:before {
    content: "\eb34";
}

.ias-icon-server_app:before {
    content: "\eb35";
}

.ias-icon-server_cloud:before {
    content: "\eb36";
}

.ias-icon-server_file:before {
    content: "\eb37";
}

.ias-icon-server_ftp:before {
    content: "\eb38";
}

.ias-icon-server_identity2:before {
    content: "\eb39";
}

.ias-icon-insert_1:before {
    content: "\eb3a";
}

.ias-icon-insert_2:before {
    content: "\eb3b";
}

.ias-icon-link:before {
    content: "\eb3c";
}

.ias-icon-link_small:before {
    content: "\eb3d";
}

.ias-icon-condition:before {
    content: "\eb3e";
}

.ias-icon-enhanced_bg_auth:before {
    content: "\eb3f";
}

.ias-icon-server_linux:before {
    content: "\eb40";
}

.ias-icon-server_message:before {
    content: "\eb41";
}

.ias-icon-server_monitor:before {
    content: "\eb42";
}

.ias-icon-server_policy:before {
    content: "\eb43";
}

.ias-icon-server_sql:before {
    content: "\eb44";
}

.ias-icon-server_suse:before {
    content: "\eb45";
}

.ias-icon-server_unix:before {
    content: "\eb46";
}

.ias-icon-server_windows:before {
    content: "\eb47";
}

.ias-icon-messages:before {
    content: "\eb4a";
}

.ias-icon-branch:before {
    content: "\eb4b";
}

.ias-icon-reassign:before {
    content: "\eb4c";
}

.ias-icon-out_of_band:before {
    content: "\eb4d";
}

.ias-icon-status_start_2:before {
    content: "\eb5a";
}

.ias-icon-process:before {
    content: "\eb5b";
}

.ias-icon-mapping:before {
    content: "\eb5c";
}

